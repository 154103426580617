<template>
    <v-col cols="12" class="pl-1 pb-1">
        <v-row class="pa-0 ma-0 mb-3">
        <hb-notification
                v-if="showAccessHoursUpgradeNotification"
                type="quick-actions"
                v-model="showAccessHoursUpgradeNotification"
                title="Enable Access Hours"
                class=""
            >
                <template v-slot:condenseActions>
                    <hb-menu
                        options
                        options-hover-type="quick-actions"
                    >
                        <v-list>
                            <v-list-item :ripple="false">
                                <v-list-item-title>Enable Access Hours</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </hb-menu>
                </template>
                <template v-slot:actions>
                    <v-row style="vertical-align: middle;" class="ma-0 pa-0">
                        
                        <v-col >
                            <hb-link class="ma-0 pa-0" href="https://help.tenantinc.com/en_US/august-2024-new-features-and-updates/access-hours-and-access-areas" target="_blank">Learn More</hb-link>
                        </v-col>
                        <v-col class="ma-0 pa-0">
                            <hb-btn small color="primary" @click="enableAccessHours()">Enable Access Hours</hb-btn>
                        </v-col>
                    </v-row>
                </template>
            </hb-notification>
        </v-row>
        <v-row v-if="template" class="pl-2 pt-0 pb-4">
            <hb-breadcrumb @click="goToList()">
                Back to Lease Templates
            </hb-breadcrumb>
        </v-row>
        <!-- <v-row v-if="activePage == 'settings-templates' && !template" class="pl-2 pt-1 pb-4">
            <hb-breadcrumb @click="$emit('showCorporateIndexPage')">
                Back to Corporate Dashboard
            </hb-breadcrumb>
        </v-row> -->
        <span v-if="!activePage && menuType == 'Corporate'">
            <hb-page-header
                title="Settings Dashboard"
                description="Configure settings for all facilities."
            >
            </hb-page-header>
        </span>

        <span v-else-if="!activePage && menuType == 'Property'">
            <hb-page-header
                title="Property"
                description="Configure settings for individual properties."
            >
            </hb-page-header>
        </span>
        <span v-else-if="template">
            <hb-page-header
                :title="template.name"
                :description="template.description"
            >
            </hb-page-header>
        </span>
        <span v-else-if="currentActivePage">
            <hb-page-header
                v-if="currentActivePage.group"
                :title="currentActivePage.group.group_name"
                :description="currentActivePage.description"
            >
            </hb-page-header>
            <hb-page-header
                v-else
                :title="currentActivePage.title"
                :description="currentActivePage.description"
            >
            </hb-page-header>
        </span>
        <span v-else>
            &nbsp;
        </span>
    </v-col>
</template>

<script>

import { EventBus } from '../../EventBus.js';
import { notificationMixin } from '../../mixins/notificationMixin.js';
import api from '../../assets/api.js'

export default {
    name : 'TopHeadings',
    mixins: [notificationMixin],
    data() {
        return {
            template: null,
            tabsShown: false,
            showAccessHoursUpgradeNotification: false,
            accessHoursSettingsEnabled: false,
            pagesList: [
                { 'name': 'settings-billing', 'title': 'Billing', 'description': 'Set your property closing time, how transactions are changed, and a cutoff for adjusting invoices.' },
                { 'name': 'settings-promotions', 'title': 'Manage Promotions and Discounts', 'description': 'Create and configure promotions and discounts.' },
                { 'name': 'settings-categories', 'title': 'Manage Space Categories', 'description': '' },
                { 'name': 'settings-products', 'title': 'Manage Products', 'description': 'Configure your products.' },
                { 'name': 'settings-national-account', 'title': 'National Accounts', 'description': 'Setup your corporate settings for National Accounts.' },
                { 'name': 'settings-space-groups', 'title': 'Space Grouping', 'description': 'View and manage various grouping profiles for your spaces.' },
                { 'name': 'settings-accounting', 'title': 'Accounting Settings', 'description': 'Set up your accounting.' },
                { 'name': 'settings-triggers', 'title': 'Manage Triggers', 'description': 'Triggers allow you to know and respond to things that happen in a user account. For example, you can know when a user is 20 days past due, or you can be alerted 1 month before a lease ends.' },
                { 'name': 'settings-automations', 'title': 'Automation Workflows', 'description': '' },
                { 'name': 'automation-group-properties', 'title': 'Delinquency Automation', 'description': '' },
                { 'name': 'settings-api-keys', 'title': 'Manage API Keys', 'description': 'Create API keys to connect our platform to your website. Be sure to keep these safe. Anyone with access to your API key has full access to your data!' },
                // { 'name': 'settings-merchandise', 'title': 'Merchandise Management', 'description': 'Manage your Merchandise and Inventory Tracking for your portfolio' },
                { 'name': 'settings-insurance', 'title': 'Manage Coverage', 'description': '' },
                { 'name': 'settings-roles', 'title': 'Users and Permissions', 'description': '' },
                { 'name': 'settings-access-control', 'title': 'Access Control', 'description': 'Configure your access control integration here.' },
                { 'name': 'settings-payment-configuration', 'title': 'Payment Processing', 'description': '' },
                { 'name': 'settings-fees', 'title': 'Fees', 'description': 'Configure all available fees.' },
                { 'name': 'settings-properties', 'title': 'Properties', 'description': 'Create and configure all properties' },
                { 'name': 'settings-property-groups', 'title': 'Property Groups', 'description': 'Create and configure all property groups.' },
                { 'name': 'settings-rent', 'title': 'Rent', 'description': '' },
                { 'name': 'settings-templates', 'title': 'Lease Configuration Library', 'description': '' },
                { 'name': 'settings-lease-configuration', 'title': 'Configure Lease', 'description': 'Choose the lease you would like to use for each type of space. If you dont see any options to set other space types, it means your property has only one type of space in it.' },
                { 'name': 'settings-tax-profiles', 'title': 'Tax', 'description': '' },
                { 'name': 'settings-tax-rates', 'title': 'Tax Rates', 'description': 'View/Edit tax information here.' },
                { 'name': 'settings-auctions', 'title': 'Auctions', 'description': 'Configure settings for the auction process.' },
                { 'name': 'settings-feature-and-amenity', 'title': 'Space Features and Amenities', 'description': 'Manage features and amenities for each property.' },
                { 'name': 'settings-document-manager', 'title': 'Document Manager', 'description': 'You can add documents to your template library and / or assign templates to each property.' },
                { 'name': 'settings-revenue-management', 'title': 'Revenue Management', 'description': 'Schedule automated and manual rent changes for tenants at your properties.' },
                { 'name': 'settings-charm', 'title': 'CHARM', 'description': 'Setup and manage your call assistant.' },
                { 'name': 'settings-services', 'title': 'Services', 'description': '' },
                { 'name': 'settings-lead-management', 'title': 'Lead Management', 'description': 'Setup and manage questions for the lead follow-up script.' },
                { 'name': 'settings-sso', 'title': 'SSO Configuration', 'description': 'Link your AD accounts here.' },
                { 'name': 'settings-notes', 'title': 'Notes', 'description': 'Manage your notes settings'},
                { 'name': 'settings-communication-templates', 'title': 'Communication Templates', 'description': 'Manage your templates for Email and SMS communications'},
                { 'name': 'settings-communications', 'title': 'Communication', 'description': 'Modify Communication settings here'},

                // { 'name': 'settings-transactional', 'title': 'Transactional', 'description': 'Set your property closing time, how transactions are charged, and a cutoff for adjusting invoices.' }
            ]
        }
    },
    created() {
        EventBus.$on('HB-Lease-Template:Name-Description', this.eventSetTabsTemplate);
    },
    computed: {
        currentActivePage(){
            if(this.activePage){
                                return this.pagesList.filter(s => s.name === this.activePage)[0];
            }
        },  
    },

    watch: {
        cmsAppTitle: {
            handler(value) {
                value?.forEach((app) => {
                    this.pagesList?.push(app);
                });
            },
            immediate: true,
            deep: true,
        },
        selectedProperty: {
            handler(value) {
                this.selectedAccessProperty = value;
            },
            immediate: true,
            deep: true
        },
        showAccessHoursUpgrade: {
            handler(value) {
                this.showAccessHoursUpgradeNotification = value;
            },
            immediate: true,
            deep: true
        }
    },

    methods: {
        goToList(){
            EventBus.$emit('HB-Lease-Templates-list')
        },
        eventSetTabsTemplate(templateObj){
            if (!this.tabsShown || !templateObj) {
                this.tabsShown = true;
                if (!templateObj) {
                    this.tabsShown = false;
                }
                this.$emit('tabs');
            }
            this.template = templateObj;
        },
        async enableAccessHours(){
            let data = {
                "access_hours_upgrade": 1
            }
            await api.put(this, api.PROPERTIES + this.selectedAccessProperty + "/property-area-management", data)
              .then(results => {
                this.showMessageNotification({ type: 'success', description: 'Access Hours enabled successfully' });
                this.accessHoursSettingsEnabled = true;
                EventBus.$emit('Access-Hours-Enabled', this.accessHoursSettingsEnabled)
              })
              .catch(error => {
                this.showMessageNotification({ type: 'error', description: error });
              })
        }
    },
    destroyed() {
        EventBus.$off('HB-Lease-Template:Name-Description', this.eventSetTabsTemplate);
    },
    props: {
        menuType : {
            type : String,
            default : 'Corporate'
        },
        activePage: {
            type : String,
            default : ''
        },
        cmsAppTitle: {
            type: Array
        },
        showAccessHoursUpgrade: {
            type: Boolean,
            default: false
        },
        selectedProperty: {
            type: String,
            default: ''
        }

    }
}
</script>

<style scoped>
    .no-focus-link{
        color: #00848e;
        text-decoration: none;
        border: 0 !important;
        outline: none !important;;
    }
</style>
